<template>
  <div class="row py-5">
    <div class="login card">
      <article class="card-body">
        <h4 class="card-title text-center mb-4 mt-1">Sign in</h4>
        <hr />
        <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" @submit.prevent="handleSubmit(login)">
          <div class="form-group">
            <ValidationProvider name="Email" rules="required|email" mode="eager" v-slot="{ errors }">
              <label for="email">Email</label>
              <input name="email" v-model="user.email" class="form-control" type="email" />
              <span  class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <router-link class="float-right" :to="{name: 'forgot-password'}">Forgot?</router-link>
            <ValidationProvider name="Password" rules="required" v-slot="{ errors }">
              <label for="password">Password</label>
              <input name="password" v-model="user.password" class="form-control" type="password" />
              <span class="text-danger form-text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block" :disabled="loading">
              <b-spinner small v-if="loading"> </b-spinner>
              Login
            </button>
          </div>
        </form>
        </ValidationObserver>
        <p>Don't have an account? <router-link :to="{ name: 'register' }">Register</router-link></p>
        <div class="col-md-12 ">
          <div class="social">
            <hr />
            <span>or</span>
          </div>
        </div>
        <div id="firebaseui-auth-container"></div>
      </article>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import 'firebase/auth';
import firebase from '@/store/modules/auth/firebase.config';
import '@/util/validation';

export default {
  name: 'Login',
  components: {
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      user: {},
      loading: false,
      feedback: '',
      redirect: this.$route.query.redirect || '/',
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$store.dispatch('auth/signin', this.user).then(() => {
        this.loading = false;
        this.$router.replace({ path: this.redirect });
      }).catch(() => {
        this.$toast.error('Invalid Email or Password', 'Login Failed');
        this.loading = false;
      });
    },
  },
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      signInSuccessUrl: this.redirect,
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          customParameters: {
            // Forces account selection even when one account is available.
            prompt: 'select_account',
          },
        },
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.GithubAuthProvider.PROVIDER_ID,
        'microsoft.com',
        'yahoo.com',
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
    };
    // The start method will wait until the DOM is loaded.
    ui.start('#firebaseui-auth-container', uiConfig);
  },
};
</script>

<style lang="stylus" scoped>
login_color = #000
social_bg_color = #fff
social_color = #aaa

.login
  margin 0 auto
  min-width 21rem
  color login_color
  .social
    position: relative;
    color social_color;
    padding 1rem 0
    span
      display: block;
      position: absolute;
      left: 50%;
      top: 2px;
      margin-left: -2rem;
      background-color social_bg_color
      width: 2rem;
      text-align: center;
    hr
      height: 1px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
</style>
